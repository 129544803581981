visible = function(obj, partial) {
      let $t            = $(obj),
          $w            = $(window),
          viewTop       = $w.scrollTop(),
          viewBottom    = viewTop + $w.height(),
          _top          = $t.offset().top,
          _bottom       = _top + $t.height(),
          compareTop    = partial === true ? _bottom : _top,
          compareBottom = partial === true ? _top : _bottom;
    return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
  };

revealFunction = function(){
  $(".reveal-on-scroll").each(function(i, el) {
    let obj = $(el);
    if (visible(obj, true)) {
      obj.css("animation-delay", obj.attr("data-animation-delay"));
      obj.addClass(obj.attr("data-animation"));
    }
  });
  $(".animate-number-when-visible").each(function(i, el) {
    let obj = $(el);
    if (visible(obj, true)) {
      let start = Number($(obj).attr("data-start"));
      let end = Number($(obj).attr("data-end"));
      let duration = Number($(obj).attr("data-duration"));
      let started = $(obj).attr("data-started")
      if (started != 1 && !isNaN(start) && !isNaN(end) && !isNaN(duration)){
        animateNumberValue(obj, start, end, duration);
      }      
    }
  });
};

$(window).scroll(function(event) {
  revealFunction();
});

$(document).ready(function(){
  revealFunction();
});

animateNumberValue = function(obj, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    $(obj).html(Math.floor(progress * (end - start) + start));
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  $(obj).attr("data-started", 1);
  window.requestAnimationFrame(step);
}

