import Turbolinks from "turbolinks";

const App = {
  sayHello(){
    console.log("Hello");
  },
  
  initEverything(){
    
  },

  viewNewsItem(newsItemId){
    let url = `/admin/news/${newsItemId}`;
    Turbolinks.visit(url);
  },

  viewCircular(id){
    let url = `/admin/circulars/${id}`;
    Turbolinks.visit(url);
  },

  viewUser(id){
    let url = `/admin/users/${id}`;
    Turbolinks.visit(url);
  },

  loadingMessageObject: null,
  loadingMessageCounter: 0,

  showLoadingMessage(message){
    if(message === undefined){
      message  = "Loading..."
    }
    if(loadingMessageCounter == 0){
      loadingMessageObject = $.notify({message: message}, {allow_dismiss: false, type: "warning", delay: 15000, placement:{from: "top", align: "center"}});
      loadingMessageCounter++;
    } else if(loadingMessageCounter > 0) {
      loadingMessageCounter++;
    }
  },

  closeLoadingMessage(){
    if(loadingMessageCounter == 1){
      loadingMessageObject.close();
      loadingMessageCounter--;
    } else if(loadingMessageCounter > 1){
      loadingMessageCounter--;
    }
    $(".modal-backdrop.fade.in").removeClass("modal-backdrop fade in");
  },

  showSuccessMessage(msg){
    let options = {};
    if (msg)
    options.message = msg;

    $.notify(options, {delay: 2000, type: "success", placement:{from: "bottom", align: "right"}});
  },

  showFailureMessage(msg){
    let options = {};
    if (msg)
    options.message = msg;

    $.notify(options, {delay: 2000, type: "danger", placement:{from: "bottom", align: "right"}});
  },

  toggleSelectAll(classSelector, reference){
    let checkboxes = $("."+classSelector);
    let selectAll = $(".select-all");
    let toggleTo = selectAll.prop("checked")? "checked": false;
    if(reference != undefined){
      toggleTo = reference.prop("checked")? "checked": false;
    }
    for(let i=0; i<checkboxes.length; i++){
      $(checkboxes[i]).prop("checked", toggleTo);
    }
  },

  setupTimePicker(elementId, minDate=null, maxDate=null){
    let defaultDate = $("#"+elementId).val();
    let options = {
      format:"HH:mm",
      sideBySide: true,
      widgetPositioning:{
        vertical: "bottom",
        horizontal: "left"
      },
    };
    if(minDate){
      options.minDate = minDate;
    }
    if(maxDate){
      options.maxDate = maxDate;
    }
    $("#"+elementId).attr("autocomplete", "false");
    $("#"+elementId).datetimepicker(options);
    if(defaultDate!=""){
      $("#"+elementId).val(defaultDate);
    }
  },

  setupDateTimePicker(elementId, minDate=null, maxDate=null){
    let defaultDate = $("#"+elementId).val();
    let options = {
      format:"YYYY-MM-DDTHH:mm",
      sideBySide: true,
      widgetPositioning:{
        vertical: "bottom",
        horizontal: "left"
      },
    };
    if(minDate){
      options.minDate = minDate;
    }
    if(maxDate){
      options.maxDate = maxDate;
    }
    $("#"+elementId).attr("autocomplete", "false");
    $("#"+elementId).datetimepicker(options);
    if(defaultDate!=""){
      $("#"+elementId).val(defaultDate);
    }
  },

  setupDatePicker(elementId, minDate=null, maxDate=null){
    let defaultDate = $("#"+elementId).val();
    let options = {
      format:"YYYY-MM-DD",
      widgetPositioning:{
        vertical: "bottom",
        horizontal: "left"
      },
    };
    if(minDate){
      options.minDate = minDate;
    }
    if(maxDate){
      options.maxDate = maxDate;
    }
    $("#"+elementId).attr("autocomplete", "false");
    $("#"+elementId).datetimepicker(options);
    if(defaultDate!=""){
      $("#"+elementId).val(defaultDate);
    }
  },

  validateUser() {
    let valid = true
    $("#user_details_username").removeClass("error-field")
    $("#user_details_password").removeClass("error-field")
    $("#user_details_name").removeClass("error-field")
    if ($("#user_details_username").val() == "" || $("#user_details_username").val() == undefined || $("#user_details_username").val() == null  ){
      $("#user_details_username").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Username")
    }
    if (($("#user_details_password").val() == "" || $("#user_details_password").val() == undefined || $("#user_details_password").val() == null) && window.location.href.indexOf("edit") == -1 ){
      $("#user_details_password").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Password")
    }
    if ($("#user_details_name").val() == "" || $("#user_details_name").val() == undefined || $("#user_details_name").val() == null  ){
      $("#user_details_name").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Name")
    }
    return valid
  },

  viewMember(id) {
    window.location = "/members/" + id
  },

  validateMember() {
    let valid = true
    $("#member_details_name").removeClass("error-field")
    $("#member_details_phone_number").removeClass("error-field")
    $("#member_details_name_of_force").removeClass("error-field")
    $("#member_details_designation").removeClass("error-field")
    $("#member_details_force_ppo_pen_number").removeClass("error-field")

    if ($("#member_details_name").val() == "" || $("#member_details_name").val() == undefined || $("#member_details_name").val() == null  ){
      $("#member_details_name").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Name")
    }
    if ($("#member_details_phone_number").val() == "" || $("#member_details_phone_number").val() == undefined || $("#member_details_phone_number").val() == null  ){
      $("#member_details_phone_number").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Phone number")
    }
    if ($("#member_details_name_of_force").val() == "" || $("#member_details_name_of_force").val() == undefined || $("#member_details_name_of_force").val() == null  ){
      $("#member_details_name_of_force").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Name of force")
    }
    if ($("#member_details_designation").val() == "" || $("#member_details_designation").val() == undefined || $("#member_details_designation").val() == null  ){
      $("#member_details_designation").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Designation")
    }
    if ($("#member_details_force_ppo_pen_number").val() == "" || $("#member_details_force_ppo_pen_number").val() == undefined || $("#member_details_force_ppo_pen_number").val() == null  ){
      $("#member_details_force_ppo_pen_number").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Registration number")
    }
    return valid
  },

  cancelAction() {
    window.history.go(-1)
  },

};

export default App